/* Estilos globales para asegurar consistencia */
html,
body,
#root {
  background-color: #f5f5f5 !important;
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-x: hidden;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 600px) {
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }

  .MuiContainer-root {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .MuiTableContainer-root {
    overflow-x: auto;
  }

  /* Ocultar sidebar en móviles */
  .MuiDrawer-paper {
    display: none;
  }

  /* Ajustar ancho de main en móviles */
  .MuiBox-root[component="main"] {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 16px !important;
    background-color: #f5f5f5 !important;
  }
}